import React, { Suspense, lazy, useEffect, useState } from 'react';
import './App.scss';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import RouteWrapper from './components/RouteHelpers/RouteWrapper';
import ProtectedRouteWrapper from './components/RouteHelpers/ProtectedRouteWrapper';
import Layout from './features/Layout/Layout';
import PageBuilderViewPageWrapper from './pages/PageBuilder/ViewPage/PageBuilderViewPageWrapper';
import PageBuilderViewPage from './pages/PageBuilder/ViewPage/PageBuilderViewPage';
import ViewPDFPageWrapper from './pages/ViewPDF/ViewPDFWrapper';
import { useLazyCheckIfSubscribeQuery } from './store/services/authApi';
import { useGetUserContextQuery } from './store/services/userContextApi';
import LoadingScreen from './components/Loading/LoadingScreen/LoadingScreen';
import ViewFileWrapper from '@pages/ViewFile/ViewFileWrapper';
import ViewFilePage from '@pages/ViewFile/ViewFilePage';

const HomePage = lazy(() => import('./pages/Home/HomePage'));
const AboutUsPage = lazy(() => import('./pages/AboutUs/AboutUsPage'));
const PricingPage = lazy(() => import('./pages/Pricing/PricingPage'));
const ContactUsPage = lazy(() => import('./pages/ContactUs/ContactUsPage'));
const ActivateKeyPage = lazy(() => import('./pages/ActivateKey/ActivateKeyPage'));
const SubscriptionManagementRoutes = lazy(() => import('./pages/SubscriptionManagement/SubscriptionManagementRoutes'));
const DashboardRoutes = lazy(() => import('./pages/Dashboard/DashboardRoutes'));
const LicensesPage = lazy(() => import('./pages/Licenses/LicencesPage'));
const SupervisorPage = lazy(() => import('./pages/Supervisors/SupervisorPage'));
const NotificationPage = lazy(() => import('./pages/Notifcations/NotificationPage'));
const BrowseFreeContentPage = lazy(() => import('./pages/BrowseFreeContent/BrowseFreeContentPage'));
const PDFViewer = lazy(() => import('./pages/PDFviewer/PDFviewer'));
const ViewPDFPage = lazy(() => import('./pages/ViewPDF/ViewPDFPage'));

function App() {
  const [checked, setChecked] = useState(false);
  //this will preload all the images that needs to be preloaded
  const [checkIfSubscribe, { data: isSubscribed }] = useLazyCheckIfSubscribeQuery();
  const { data: userData } = useGetUserContextQuery('');

  useEffect(() => {
    const user = userData as any;
    if (user) {
      if (user?.email) {
        checkIfSubscribe(user?.email);
      }
    }
  }, [userData]);

  useEffect(() => {
    const check = isSubscribed as [];
    if (check) {
      if (check.length > 0) {
        setChecked(true);
      } else {
        setChecked(false);
      }
    }
  }, [isSubscribed]);

  return (
    <div className="App" data-testid="app">
      <BrowserRouter>
        <Layout>
          <Suspense fallback={<LoadingScreen />}>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route element={<RouteWrapper />}>
                <Route path="/howto" element={<AboutUsPage />} />
                <Route path="/browse" element={<BrowseFreeContentPage />} />
                <Route path="/whatsnew" element={<PricingPage />} />
                <Route path="/membership" element={<ContactUsPage />} />
                <Route path="/activate" element={<ActivateKeyPage />} />
                <Route path="/dashboard/*" element={<DashboardRoutes />} />
                <Route path="/pdf" element={<PDFViewer />} />
                <Route
                  path="/:slug"
                  element={
                    <PageBuilderViewPageWrapper>
                      <PageBuilderViewPage />
                    </PageBuilderViewPageWrapper>
                  }
                />
              </Route>

              <Route element={<ProtectedRouteWrapper />}>
                <Route path="/subscription/*" element={<SubscriptionManagementRoutes />} />
                <Route path="/licenses" element={<LicensesPage />} />
                <Route path="/supervisors" element={<SupervisorPage />} />
                <Route path="/notifications" element={<NotificationPage />} />
              </Route>

              <Route
                path="/view_pdf"
                element={
                  <ViewPDFPageWrapper>
                    <ViewPDFPage />
                  </ViewPDFPageWrapper>
                }
              />
              <Route
                path="/view_file"
                element={
                  <ViewFileWrapper>
                    <ViewFilePage />
                  </ViewFileWrapper>
                }
              />
              <Route path="*" element={<Navigate replace to="/" />} />
            </Routes>
          </Suspense>
        </Layout>
      </BrowserRouter>
    </div>
  );
}

export default App;

import { createSlice } from '@reduxjs/toolkit';
import { toDecimal, updateLocalStorage } from '../../../../helpers/utilityFuncions';
import { nth } from '../../../../helpers/utilityFuncions';

interface CreateSubscriptionProps {
  initialized: boolean;
  data: SubscriptionDataType;
  dataDisplay: SubscriptionDataDisplayType;
}

export type SubscriptionDataType = {
  user_id: string;
  product_id: string;
  price: number;
  quantity: number;
  subscription_period: number;
  subsite_short_code: string;
  is_group: boolean;
  is_auto_renewal: boolean;
  payment_term: number;
  subtotal: number;
  tax: number;
  transaction_type?: string;
  tax_percentage: number;
  total: number;
  subscription_id?: string;
  is_pro_rata?: boolean;
  pro_rata_num?: number;
  pro_rata_payment?: number;
  pro_rata_subtotal?: number;
  pro_rata_tax?: number;
  pro_rata_price_per_licence?: number;
  remaining_months?: number;
  selected_account?: string;
  unselected_account?: string;
};

export type SubscriptionDataDisplayType = {
  subsiteName: string;
  btnText: string;
  subtotalDisplay: string[];
  taxDisplay: string[];
  totalDisplay: string[];
  pay2givesDisplay: string[];
  pay3givesDisplay: string[];
};

const initialState: CreateSubscriptionProps = {
  initialized: false,
  data: {
    user_id: '',
    product_id: '',
    price: 0,
    quantity: 0,
    subscription_period: 0,
    subsite_short_code: '',
    is_group: false,
    is_auto_renewal: false,
    payment_term: 1,
    subtotal: 0,
    tax: 0,
    transaction_type: '',
    tax_percentage: 0,
    total: 0,
    subscription_id: '',
    is_pro_rata: false,
    pro_rata_num: 0,
    pro_rata_payment: 0,
    pro_rata_tax: 0,
    pro_rata_price_per_licence: 0,
    pro_rata_subtotal: 0,
    remaining_months: 0,
    selected_account: '',
    unselected_account: '',
  },
  dataDisplay: {
    subsiteName: '',
    btnText: 'Proceed',
    subtotalDisplay: [],
    taxDisplay: [],
    totalDisplay: [],
    pay2givesDisplay: [],
    pay3givesDisplay: [],
  },
};

export const createSubscriptionSlice = createSlice({
  name: 'createSubscription',
  initialState,
  reducers: {
    initializeWithLocalStorage: {
      reducer: (state, action: any) => {
        //get local storage details
        const prevpageDetails = localStorage.getItem(action.payload.email)
          ? JSON.parse(localStorage.getItem(action.payload.email) ?? '{}')
          : undefined;

        if (prevpageDetails) {
          //data
          state.data.user_id = prevpageDetails.user_id;
          state.data.product_id = prevpageDetails.product_id;
          state.data.quantity = prevpageDetails.quantity;
          state.data.price = prevpageDetails.price;
          state.data.subscription_period = prevpageDetails.subscription_period;
          state.data.subsite_short_code = prevpageDetails.subsite_short_code;
          state.data.is_group = prevpageDetails.is_group;
          state.data.is_auto_renewal = prevpageDetails.is_auto_renewal;
          state.data.payment_term = prevpageDetails.payment_term;
          state.data.subtotal = prevpageDetails.subtotal;
          state.data.tax = prevpageDetails.tax;
          state.data.tax_percentage = prevpageDetails.tax_percentage;
          state.data.total = prevpageDetails.total;
          state.data.transaction_type = prevpageDetails.transaction_type;
          state.data.subscription_id = prevpageDetails.subscription_id;
          state.data.is_pro_rata = prevpageDetails.is_pro_rata;
          state.data.pro_rata_num = prevpageDetails.pro_rata_num;
          state.data.pro_rata_payment = prevpageDetails.pro_rata_payment;
          state.data.pro_rata_subtotal = prevpageDetails.pro_rata_subtotal;
          state.data.pro_rata_tax = prevpageDetails.pro_rata_tax;
          state.data.pro_rata_price_per_licence = prevpageDetails.pro_rata_price_per_licence;
          state.data.remaining_months = prevpageDetails.remaining_months;
          state.data.selected_account = prevpageDetails.selected_account;
          state.data.unselected_account = prevpageDetails.unselected_account;
          //display
          state.dataDisplay.subsiteName = prevpageDetails.subsiteName;
          state.dataDisplay.btnText = prevpageDetails.btnText;
          state.dataDisplay.subtotalDisplay = prevpageDetails.subtotalDisplay;
          state.dataDisplay.taxDisplay = prevpageDetails.taxDisplay;
          state.dataDisplay.totalDisplay = prevpageDetails.totalDisplay;
          state.dataDisplay.pay2givesDisplay = prevpageDetails.pay2givesDisplay;
          state.dataDisplay.pay3givesDisplay = prevpageDetails.pay3givesDisplay;

          state.initialized = true;
        }
      },
      prepare: (email: string): any => {
        return {
          payload: {
            email,
          },
        };
      },
    },
    clearLocalStorage: {
      reducer: (state) => {
        state.initialized = false;
      },
      prepare: (email: string): any => {
        //clear local storage details
        localStorage.removeItem(email);
      },
    },
    saveCreateState: {
      reducer: (state, action: any) => {
        //store the form data to localstorage for semi caching
        localStorage.setItem(action.payload.email, JSON.stringify({ ...action.payload.data, ...action.payload.displayData }));

        const {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          user_id,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          product_id,
          quantity,
          price,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          subscription_period,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          is_group,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          subsite_short_code,
          subsiteName,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          is_auto_renewal,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          payment_term,
          subtotal,
          tax,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          transaction_type,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          subscription_id,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          is_pro_rata,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          pro_rata_num,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          pro_rata_payment,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          pro_rata_subtotal,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          pro_rata_tax,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          pro_rata_price_per_licence,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          remaining_months,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          selected_account,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          unselected_account,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          tax_percentage,
          total,
        } = action.payload.data;
        const { btnText, subtotalDisplay, taxDisplay, totalDisplay, pay2givesDisplay, pay3givesDisplay } =
          action.payload.displayData;

        //data
        state.data.user_id = user_id;
        state.data.product_id = product_id;
        state.data.quantity = quantity;
        state.data.price = price;
        state.data.subscription_period = subscription_period;
        state.data.subsite_short_code = subsite_short_code;
        state.data.is_group = is_group;
        state.data.is_auto_renewal = is_auto_renewal;
        state.data.payment_term = payment_term;
        state.data.subtotal = subtotal;
        state.data.tax = tax;
        state.data.transaction_type = transaction_type;
        state.data.tax_percentage = tax_percentage;
        state.data.total = total;
        state.data.subscription_id = subscription_id;
        state.data.is_pro_rata = is_pro_rata;
        state.data.pro_rata_num = pro_rata_num;
        state.data.pro_rata_payment = pro_rata_payment;
        state.data.pro_rata_subtotal = pro_rata_subtotal;
        state.data.pro_rata_tax = pro_rata_tax;
        state.data.pro_rata_price_per_licence = pro_rata_price_per_licence;
        state.data.remaining_months = remaining_months;
        state.data.selected_account = selected_account;
        state.data.unselected_account = unselected_account;

        //display
        state.dataDisplay.subsiteName = subsiteName;
        state.dataDisplay.btnText = btnText;
        state.dataDisplay.subtotalDisplay = subtotalDisplay;
        state.dataDisplay.taxDisplay = taxDisplay;
        state.dataDisplay.totalDisplay = totalDisplay;
        state.dataDisplay.pay2givesDisplay = pay2givesDisplay;
        state.dataDisplay.pay3givesDisplay = pay3givesDisplay;

        state.initialized = true;
      },
      prepare: (
        user_id: string,
        product_id: string,
        email: string,
        subscription_period: number,
        quantity: number,
        price: number,
        subsite_short_code: string,
        subsiteName: string,
        is_group,
        is_auto_renewal: boolean,
        payment_term: number,
        tax: number,
        tax_percentage: number,
        transaction_type?: string,
        subscription_id?: string,
        is_pro_rata?: boolean,
        pro_rata_num?: number,

        pro_rata_payment?: number,
        pro_rata_subtotal?: number,
        pro_rata_tax?: number,
        pro_rata_price_per_licence?: number,

        remaining_months?: number,
        selected_account?: string,
        unselected_account?: string,
        current_payment_term?: number,
        is_retry_mode?: boolean
      ): any => {
        
        const convertedPrice = toDecimal(price);
        const convertedTax = toDecimal(tax);
        const subtotal = toDecimal((quantity * convertedPrice) + Number(convertedTax));
        const total = toDecimal(subtotal +  Number(pro_rata_subtotal ?? 0));
        const totalDisplay = total.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).split('.');
        const pay2givesDisplay = (total / 2)
          .toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })
          .split('.');
        const pay3givesDisplay = (total / 3)
          .toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })
          .split('.');

        let displayData: SubscriptionDataDisplayType = {
          //these are for the weird small zero effect display in UI
          subsiteName: subsiteName,

          subtotalDisplay: subtotal.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).split('.'),
          taxDisplay: tax?.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).split('.'),
          totalDisplay,
          pay2givesDisplay,
          pay3givesDisplay,
          btnText: 'Pay',
        };

        switch (payment_term) {
          case 1:
            displayData.btnText = `${!is_retry_mode ? 'Pay Securely' : 'Retry'} $${
              // displayData.btnText = `${!is_retry_mode ? 'Confirm Payment of' : 'Retry'} $ ${
              totalDisplay[0]
            }.<span>${totalDisplay[1] ?? '00'}</span>`;
            // }.<span class='decimal'>${totalDisplay[1] ?? '00'}</span>`;
            break;
          case 2:
            displayData.btnText = `${!is_retry_mode ? 'Pay' : 'Retry'} ${nth(current_payment_term ?? 1, true)} Payment of $ ${
              pay2givesDisplay[0]
            }.<span class='decimal'>${pay2givesDisplay[1] ?? '00'}</span>`;
            break;
          case 3:
            displayData.btnText = `${!is_retry_mode ? 'Pay' : 'Retry'} ${nth(current_payment_term ?? 1, true)} Payment of $ ${
              pay3givesDisplay[0]
            }.<span class='decimal'>${pay3givesDisplay[1] ?? '00'}</span>`;
            break;
        }

        return {
          payload: {
            email,
            data: {
              user_id,
              product_id,
              subscription_period,
              quantity,
              price: convertedPrice,
              subsite_short_code,
              subsiteName,
              is_group,
              is_auto_renewal,
              payment_term,
              subtotal,
              tax: convertedTax,
              tax_percentage,
              total,
              transaction_type,
              subscription_id,
              is_pro_rata,
              pro_rata_num,
              pro_rata_payment,
              pro_rata_subtotal,
              pro_rata_tax,
              pro_rata_price_per_licence,
              remaining_months,
              selected_account,
              unselected_account,
            },
            displayData,
          },
        };
      },
    },
    updatePaymentTermSelected: {
      reducer: (state, action: any) => {
        //update local storage
        updateLocalStorage(action.payload.email, {
          payment_term: action.payload.paymentTerm,
          btnText: action.payload.btnText,
        });

        state.data.payment_term = action.payload.paymentTerm;
        state.dataDisplay.btnText = action.payload.btnText;
      },
      prepare: (email: string, paymentTerm: number, btnText: string): any => {
        return {
          payload: {
            email,
            paymentTerm,
            btnText,
          },
        };
      },
    },
  },
});

export default createSubscriptionSlice.reducer;

export const { initializeWithLocalStorage, saveCreateState, updatePaymentTermSelected } = createSubscriptionSlice.actions;

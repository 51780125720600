import { Suspense, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import config from '../../config';
import cookie from '../../helpers/cookieHelper';
import LoadingScreen from '../Loading/LoadingScreen/LoadingScreen';
import { useAppDispatch } from '../../store/hooks/storeHooks';
import { resetSelection } from '../../features/SubsiteHexagons/store/subsiteHexagonSlice';
import { redirectLogin } from '../../helpers/utilityFuncions';

const RouteWrapper = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  // const navigate = useNavigate();

  useEffect(() => {
    // if (cookie.isLoggedIn()) {
    //   navigate('dashboard/tr');
    // } else {
    //   window.location.href = `${config.subsitesUrl.main}/login/redirect/${config.subsiteCode}`;
    // }
  }, []);

  useEffect(() => {
    //this ensures that the Hexagon selection state is always at reset
    //changing of selection mode is triggered inside child pages
    dispatch(resetSelection());
  }, []);

  //ensures that the screen scrolls to top when location changes
  useEffect(() => window.scrollTo(0, 0), [location]);

  return (
    <Suspense fallback={<LoadingScreen />}>
      <Outlet />
    </Suspense>
  );
};

export default RouteWrapper;

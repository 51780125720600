import { useRef, useEffect, useMemo } from 'react';
import { useNode, useEditor } from '@craftjs/core';
import SunEditor from 'suneditor-react';
import katex from 'katex';

import type SunEditorCore from 'suneditor/src/lib/core';

import './RichText.scss';
import { SunEditorOptions } from 'suneditor/src/options';

type RichTextProps = {
  content: string;
};

function RichText({ content }: Partial<RichTextProps>) {
  const {
    id,
    connectors: { connect, drag },
    actions: { setProp },
    dom,
    isHover,
  } = useNode((node) => ({
    isHover: node.events.hovered,
    dom: node.dom,
  }));
  const { enabled, isActive } = useEditor((state) => ({
    enabled: state.options.enabled,
    isActive: state.nodes[id].events.selected,
  }));
  const editor = useRef<SunEditorCore>();
  const getOptions: SunEditorOptions = useMemo(() => {
    return {
      mode: 'inline',
      katex: katex,
      font: ['Arial', 'Comic Sans MS', 'Courier New', 'Impact', 'Georgia', 'Montserrat', 'tahoma', 'Trebuchet MS', 'Verdana'],
      buttonList: [
        ['font', 'fontSize'],
        ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
        ['fontColor', 'hiliteColor', 'textStyle'],
        ['paragraphStyle', 'blockquote'],
        ['outdent', 'indent', 'align'],
        ['horizontalRule', 'list', 'lineHeight', 'table', 'link', 'math'],
      ],
      toolbarContainer: '#pg-sun-editor-toolbar',
    };
  }, []);

  // The sunEditor parameter will be set to the core suneditor instance when this function is called
  const getSunEditorInstance = (sunEditor: SunEditorCore) => {
    editor.current = sunEditor;
  };

  useEffect(() => {
    if (dom) {
      if (isActive || isHover) dom.classList.add('element-rich-text-selected');
      else dom.classList.remove('element-rich-text-selected');
    }
  }, [dom, isActive, isHover]);

  useEffect(() => {
    editor.current?.setOptions(getOptions);
  }, [enabled]);

  return (
    <div
      ref={(ref: HTMLDivElement) => {
        connect(drag(ref));
      }}
      className="tw-w-full"
    >
      <SunEditor
        height="100%"
        disable={!enabled}
        getSunEditorInstance={getSunEditorInstance}
        setContents={content}
        setOptions={getOptions}
        onChange={(content) => {
          setProp((prop: any) => (prop.content = content), 500);
        }}
        placeholder="Enter content here"
      />
    </div>
  );
}

RichText.craft = {
  displayName: 'Rich Text',
  props: {
    content: '',
  },
};

export default RichText;

import { createSlice } from '@reduxjs/toolkit';
import { ExplicitTeachingResource, TRElementGroup } from '../../../pages/Dashboard/TeachingResources/data/data';

interface MenuHexagonProps {
  selectedTerm: any;
  selectedYear: any;
  selectedElement: string | null;
  showAllElementGroups: any;
  selectedExplicitContent: any;
  selectedEGResource: any;
  initialResources: any;
}

const initialState: MenuHexagonProps = {
  selectedTerm: [],
  selectedYear: [],
  selectedElement: null,
  showAllElementGroups: '',
  selectedExplicitContent: 0,
  selectedEGResource: [],
  initialResources: [],
};

export const menuHexagonSlice = createSlice({
  name: 'menuHexagon',
  initialState,
  reducers: {
    resetSelection: (state) => {
      state.selectedTerm = initialState.selectedTerm;
      state.selectedYear = initialState.selectedYear;
      state.selectedExplicitContent = initialState.selectedExplicitContent;
      state.selectedEGResource = initialState.selectedEGResource;
    },
    resetShowAll: (state) => {
      state.showAllElementGroups = initialState.showAllElementGroups;
    },
    setSelectedTerm: {
      reducer: (state, action: any) => {
        // state.selectedTerm = elementGroupByGrade.find((item: any) => item.term === action.payload.selectedTerm)?.term ?? '';
        const selectedTerm = action.payload.selectedTerm;
        state.selectedTerm = selectedTerm?.map((i: any) => String(i));
      },
      prepare: (selectedTerm: any[]): any => {
        return {
          payload: {
            selectedTerm,
          },
        };
      },
    },
    setSelectedYear: {
      reducer: (state, action: any) => {
        // state.selectedYear = elementGroupByConcept.find((item: any) => item.id === action.payload.selectedYear)?.id ?? '';
        const selectedYear = action.payload.selectedYear;
        state.selectedYear = selectedYear?.map((i: any) => String(i));
      },
      prepare: (selectedYear: any[]): any => {
        return {
          payload: {
            selectedYear,
          },
        };
      },
    },
    setSelectedElement: {
      reducer: (state, action: any) =>{
        state.selectedElement = action.payload.selectedElement;
      },
      prepare: (selectedElement) => {
        return {
          payload: {
            selectedElement,
          }
        }
      },
    },
    setShowAllElementGroups: {
      reducer: (state) => {
        state.showAllElementGroups = 'showall';
      },
      prepare: (showAllElementGroups: string): any => {
        return {
          payload: {
            showAllElementGroups,
          },
        };
      },
    },
    setSelectedExplicitContent: {
      reducer: (state, action: any) => {
        state.selectedExplicitContent =
          ExplicitTeachingResource.find((item: any) => item.id === action.payload.selectedExplicitContent)?.id ?? '';
      },
      prepare: (selectedExplicitContent: number): any => {
        return {
          payload: {
            selectedExplicitContent,
          },
        };
      },
    },
    setSelectedEGResource: {
      reducer: (state, action: any) => {
        // state.selectedEGResource = TRElementGroup.find((item: any) => item?.id === action.payload.selectedEGResource)?.id ?? '';
        const selectedEGResource = action.payload.selectedEGResource;
        state.selectedEGResource = selectedEGResource.map((resourceId: any) => String(resourceId));
      },
      prepare: (selectedEGResource: any[]): any => {
        return {
          payload: {
            selectedEGResource,
          },
        };
      },
    },
    setInitialResource: {
      reducer: (state) => {
        state.initialResources = TRElementGroup.map((item: any) => item?.id);
      },
      prepare: (initialResources: any): any => {
        return {
          payload: {
            initialResources,
          },
        };
      },
    },
  },
});

export default menuHexagonSlice.reducer;

export const {
  setSelectedTerm,
  resetSelection,
  setSelectedYear,
  setSelectedElement,
  setShowAllElementGroups,
  resetShowAll,
  setSelectedExplicitContent,
  setSelectedEGResource,
  setInitialResource,
} = menuHexagonSlice.actions;

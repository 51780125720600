import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { Editor, Frame } from '@craftjs/core';
import lz from 'lzutf8';

import useRouterDom from '../../../hooks/useRouterDom';
import { useLazyFindOnePageQuery } from '../../../features/PageBuilder/store/pageBuilderApi';

import LoadingScreen from '../../../components/Loading/LoadingScreen/LoadingScreen';
import RenderNode from '../../../features/PageBuilder/Editor/RenderNode';

import Container from '../../../features/PageBuilder/Elements/Container';
import Text from '../../../features/PageBuilder/Elements/Text';
import RichText from '../../../features/PageBuilder/Elements/RichText';
import Image from '../../../features/PageBuilder/Elements/Image';
import VimeoVideo from '../../../features/PageBuilder/Elements/VimeoVideo';
import { useLocation } from 'react-router-dom';

import '../PageBuilder.scss';
import './PageBuilderViewPage.scss';

function PageBuilderViewPage() {
  const { urlParams } = useRouterDom();
  const [ getOnePage ] = useLazyFindOnePageQuery();
  const [ pageData, setPageData ] = useState<any>(undefined);
  const [ isLoading, setIsLoading ] = useState<boolean>(true);
  const location = useLocation();

  useEffect(() => {
    loadPageContent();
  }, [location]);

  useEffect(() => {
    loadPageContent();
    const flexRowAsideElement = document.querySelector('.flex-row aside');
    flexRowAsideElement?.classList.add('hideSideMenu');
    return () => {
      flexRowAsideElement?.classList.remove('hideSideMenu');
    };
  }, []);

  const loadPageContent = async () => {
    setIsLoading(true);
    const { data } = await getOnePage({ slug: urlParams.slug, status: 'Published' });
    setPageData(lz.decompress(lz.decodeBase64(data.page_data)));
    setIsLoading(false);
  }

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : pageData ? (
        <div className="tw-w-full">
          <Editor resolver={{ Container, Text, RichText, Image, VimeoVideo }} enabled={false} onRender={RenderNode}>
            <div className="tw-relative tw-z-[1040] tw-flex tw-flex-row">
              <div className="tw-flex tw-w-full tw-flex-col">
                <div className="page-editor tw-flex tw-flex-col">
                  <div className="tw-flex tw-flex-row">
                    <Frame data={pageData} />
                    {/* <Element
                        canvas
                        is={Container}
                        width="100%"
                        height="100vh"
                        background={{ r: 255, g: 255, b: 255, a: 1 }}
                        padding={['40', '40', '40', '40']}
                        custom={{ displayName: 'Page' }}
                      /> */}
                    {/* </Frame> */}
                  </div>
                </div>
              </div>
            </div>
          </Editor>
        </div>
      ) : (
        <Navigate replace to="/" />
      )}
    </>
  );
}

export default PageBuilderViewPage;

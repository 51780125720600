import config from '../../../config';

export const menuItems = [
  {
    displayName: 'Home',
    urlPath: '/',
    isProtected: false,
    hideAfterLogin: false,
    defaultSelected: true,
    chainUrls: [],
  },
  {
    displayName: 'Teaching Resources',
    urlPath: 'dashboard/tr',
    isProtected: false,
  },
  {
    displayName: 'How to use Teaching Resources',
    urlPath: `${config.pageBuilderUrls.main}/use-teaching-resources`,
    isProtected: false,
  },
  {
    displayName: 'How to Plan, Implement & Evaluate the First and Subsequent Lessons',
    urlPath: `${config.pageBuilderUrls.main}/plan-implement-evaluate`,
    isProtected: false,
  },
  {
    displayName: 'Professional Learning',
    urlPath: `${config.pageBuilderUrls.main}/professional-learning`,
    isProtected: false,
  },
  // // {
  // //   displayName: 'Subsites',
  // //   urlPath: '',
  // //   isProtected: false,
  // //   hideAfterLogin: false,
  // //   dropdownId: 'subsites', //provide this if the menu is a dropdown
  // // },
  // {
  //   displayName: 'How to use our Resources',
  //   urlPath: 'howto',
  //   isProtected: false,
  //   // hideAfterLogin: true,
  // },
  // {
  //   displayName: 'Browse free Resources',
  //   urlPath: 'browse',
  //   isProtected: false,
  //   dropdownId: 'browse',
  //   hideAfterLogin: true,
  // },
  // {
  //   displayName: "What's New",
  //   urlPath: 'whatsnew',
  //   isProtected: false,
  //   // hideAfterLogin: true,
  // },
  {
    displayName: 'My Account',
    urlPath: '/subscription/*',
    sideMenuId: 'myaccount',
    isProtected: true,
    hasSidebar: true,
  },
  // {
  //   displayName: 'Membership',
  //   urlPath: 'membership',
  //   isProtected: false,
  //   hideAfterLogin: true,
  // },
  // {
  //   displayName: 'Dashboard',
  //   urlPath: 'dashboard/*',
  //   isProtected: true,
  //   hasSidebar: true,
  // },
  {
    displayName: 'What’s New?',
    urlPath: `${config.pageBuilderUrls.main}/whats-new`,
    isProtected: false,
  },
  {
    displayName: 'Digital Resources',
    urlPath: `${config.pageBuilderUrls.main}/digital-resources`,
    isProtected: false,
  },
  {
    displayName: 'Student Resources',
    urlPath: `${config.pageBuilderUrls.main}/student-learning-resources`,
    isProtected: false,
  },
  {
    displayName: 'Maths Scope and Sequences',
    urlPath: `${config.pageBuilderUrls.main}/dashboard/tr/scopeandsequence?selected=0`,
    isProtected: true,
  },
  {
    displayName: 'Maths Concept Sequences',
    urlPath: `dashboard/tr/scopeandsequence?selected=0`,
    isProtected: false,
  },
  {
    displayName: 'Assessment & Tracking',
    urlPath: `${config.pageBuilderUrls.main}/assessment-tracking-tool`,
    isProtected: false,
  },
  {
    displayName: 'Research-base',
    urlPath: `${config.pageBuilderUrls.main}/research-base`,
    isProtected: false,
  },
  {
    displayName: 'Browse free resources',
    urlPath: `${config.pageBuilderUrls.main}/free-resources`,
    isProtected: false,
  },
];

export const menuItemsNotSubscribed = [
  {
    displayName: 'Home',
    urlPath: '/',
    isProtected: false,
    hideAfterLogin: false,
    defaultSelected: true,
    chainUrls: [],
  },
  {
    displayName: 'Teaching Resources',
    urlPath: 'dashboard/tr',
    isProtected: false,
  },
  {
    displayName: 'How to use Teaching Resources',
    urlPath: `${config.pageBuilderUrls.main}/use-teaching-resources`,
    isProtected: false,
  },
  {
    displayName: 'How to Plan, Implement & Evaluate the First and Subsequent Lessons',
    urlPath: `${config.pageBuilderUrls.main}/plan-implement-evaluate`,
    isProtected: false,
  },
  {
    displayName: 'Professional Learning',
    urlPath: `${config.pageBuilderUrls.main}/professional-learning`,
    isProtected: false,
  },
  {
    displayName: 'My Account',
    urlPath: '/subscription/*',
    sideMenuId: 'myaccount',
    isProtected: true,
    hasSidebar: true,
  },
  {
    displayName: 'What’s New?',
    urlPath: `${config.pageBuilderUrls.main}/whats-new`,
    isProtected: false,
  },
  {
    displayName: 'Digital Resources',
    urlPath: `${config.pageBuilderUrls.main}/digital-resources`,
    isProtected: false,
  },
  {
    displayName: 'Student Resources',
    urlPath: `${config.pageBuilderUrls.main}/student-learning-resources`,
    isProtected: false,
  },
  {
    displayName: 'Maths Scope and Sequences',
    urlPath: `${config.pageBuilderUrls.main}/tr-s-and-s`,
    isProtected: false,
  },
  {
    displayName: 'Maths Concept Sequences',
    urlPath: `${config.pageBuilderUrls.main}/tr-s-and-s`,
    isProtected: false,
  },
  {
    displayName: 'Assessment & Tracking',
    urlPath: `${config.pageBuilderUrls.main}/assessment-tracking`,
    isProtected: false,
  },
  {
    displayName: 'Research-base',
    urlPath: `${config.pageBuilderUrls.main}/research-base`,
    isProtected: false,
  },
  {
    displayName: 'Free Resources',
    urlPath: `${config.pageBuilderUrls.main}/free-resources`,
    isProtected: false,
  },
];

export const sidemenuItems = [
  {
    sideMenuId: 'dashboard',
    pathname: ['dashboard', 'supervisors', 'notifications'],
    items: [
      {
        displayName: 'Teaching Resources',
        urlPath: 'dashboard/tr',
        icon: 'TR',
        isTextIcon: true,
        hasResourceFilter: true,
        chainUrls: ['dashboard/tr/scopeandsequence', 'dashboard/tr/ultimatepage/page', 'dashboard/tr/zonepage/page'],
      },
      {
        displayName: 'Supervisors',
        urlPath: 'supervisors',
        icon: 'supervisor',
      },
      {
        displayName: 'Notifications',
        urlPath: 'notifications',
        icon: 'bell',
      },
    ],
  },
  {
    sideMenuId: 'myaccount',
    pathname: ['subscription', 'licenses'],
    items: [
      {
        displayName: 'Subscription Management',
        urlPath: 'subscription/tr',
        mainPath: 'subscription',
        icon: 'money',
        isCollapsed: true,
        submenu: [
          {
            displayName: 'Create a Subscription',
            urlPath: 'subscription/tr',
            chainUrls: [
              'subscription/tr/checkout',
              'subscription/tr/payment',
              'subscription/tr/personal',
              'subscription/tr/school',
              'subscription/tr/paymentdetails',
            ],
          },
          {
            displayName: 'My Subscriptions',
            urlPath: 'subscription/tr/mysubscriptions',
            chainUrls: [
              'subscription/tr/subscriptioninfo',
              'subscription/tr/managelicenses',
              'subscription/tr/addlicences',
              'subscription/tr/renew',
              'subscription/tr/activatelicence',
              'subscription/tr/renewpaymentdetails',
            ],
          },
          {
            displayName: 'Invoices',
            urlPath: 'subscription/tr/invoices',
          },
          {
            displayName: 'My Profile',
            urlPath: 'subscription/tr/profile',
          },
          // {
          //   displayName: 'Pending Payments',
          //   urlPath: 'subscription/pendingpayments',
          //   badgeId: 'pendingpayment',
          // },
        ],
      },
      {
        displayName: 'Licences',
        urlPath: 'licenses',
        icon: 'key',
      },
    ],
  },
];

export const sideItems = [
  {
    sideMenuId: 'sidebar',
    pathname: ['dashboard', 'supervisors', 'notifications', 'subscription', 'licenses', ''],
    items: [
      {
        displayName: 'Teaching Resources',
        urlPath: 'dashboard/tr',
        icon: 'TRs',
        isTextIcon: true,
      },
      {
        displayName: `What's New?`,
        urlPath: `${config.pageBuilderUrls.main}/whats-new`,
        icon: 'star',
      },
      // {
      //   displayName: 'My Notifications',
      //   // urlPath: window.location.pathname.substring(1),
      //   icon: 'comment',
      // },
      {
        displayName: 'My Account',
        urlPath: 'subscription/*',
        icon: 'circle-user',
        sideMenuId: 'myaccount',
        hasSidebar: true,
      },
      {
        displayName: 'Scope & Sequences',
        urlPath: '/dashboard/tr/scopeandsequence',
        icon: 'S&S',
        isTextIcon: true,
      },
      {
        displayName: 'Concept Zone Sequences',
        urlPath: '/dashboard/tr/scopeandsequence',
        icon: 'zcs',
        isTextIcon: true,
      },
      {
        displayName: 'Research',
        urlPath: `${config.pageBuilderUrls.main}/research-base`,
        icon: 'book-open',
      },
      {
        displayName: 'TPL',
        urlPath: `${config.pageBuilderUrls.main}/professional-learning`,
        icon: 'screen-users',
      },
      {
        displayName: 'Contact Us',
        urlPath: `${config.pageBuilderUrls.main}/about-contact`,
        icon: 'envelope',
      },
    ],
  },
];

export const sideItemsNotSubscribed = [
  {
    sideMenuId: 'sidebar',
    pathname: ['dashboard', 'supervisors', 'notifications', 'subscription', 'licenses', ''],
    items: [
      {
        displayName: 'Teaching Resources',
        urlPath: 'dashboard/tr',
        icon: 'TRs',
        isTextIcon: true,
      },
      {
        displayName: `What's New?`,
        urlPath: `${config.pageBuilderUrls.main}/whats-new`,
        icon: 'star',
      },
      // {
      //   displayName: 'My Notifications',
      //   // urlPath: window.location.pathname.substring(1),
      //   icon: 'comment',
      // },
      {
        displayName: 'My Account',
        urlPath: 'subscription/*',
        icon: 'circle-user',
        sideMenuId: 'myaccount',
        hasSidebar: true,
      },
      {
        displayName: 'Scope & Sequences',
        urlPath: `${config.pageBuilderUrls.main}/scope-sequences`,
        icon: 'S&S',
        isTextIcon: true,
      },
      {
        displayName: 'Concept Zone Sequences',
        urlPath: `${config.pageBuilderUrls.main}/scope-sequences`,
        icon: 'zcs',
        isTextIcon: true,
      },
      {
        displayName: 'Research',
        urlPath: `${config.pageBuilderUrls.main}/research-base`,
        icon: 'book-open',
      },
      {
        displayName: 'TPL',
        urlPath: `${config.pageBuilderUrls.main}/professional-learning`,
        icon: 'screen-users',
      },
      {
        displayName: 'Contact Us',
        urlPath: `${config.pageBuilderUrls.main}/about-contact`,
        icon: 'envelope',
      },
    ],
  },
];

export const sidemenuItem = [
  {
    sideMenuId: 'myaccount',
    pathname: ['subscription', 'licenses'],
    items: [
      {
        displayName: 'My Profile',
        urlPath: 'subscription/tr/profile',
        mainPath: 'myprofile',
        icon: 'circle-user',
      },
      {
        displayName: 'TR Subscription Management',
        urlPath: 'subscription/tr',
        mainPath: 'subscription',
        icon: 'money',
        isCollapsed: true,
        submenu: [
          {
            displayName: 'Create a Subscription',
            urlPath: 'subscription/tr',
            chainUrls: [
              'subscription/tr/checkout',
              'subscription/tr/payment',
              'subscription/tr/personal',
              'subscription/tr/school',
              'subscription/tr/paymentdetails',
            ],
          },
          {
            displayName: 'My Subscriptions',
            urlPath: 'subscription/tr/mysubscriptions',
            chainUrls: [
              'subscription/tr/subscriptioninfo',
              'subscription/tr/managelicenses',
              'subscription/tr/addlicences',
              'subscription/tr/renew',
              'subscription/tr/activatelicence',
              'subscription/tr/renewpaymentdetails',
            ],
          },
          {
            displayName: 'Invoices',
            urlPath: 'subscription/tr/invoices',
          },
          // {
          //   displayName: 'Pending Payments',
          //   urlPath: 'subscription/pendingpayments',
          //   badgeId: 'pendingpayment',
          // },
        ],
      },

      // {
      //   displayName: 'Licenses',
      //   urlPath: 'licenses',
      //   icon: 'key',
      // },
    ],
  },
];

export const resourceByGrade = [
  {
    id: 'kinder',
    name: 'Foundational / Prep / Reception / Kindergarten',
    color: 'green',
    bg_faded_color: 'bg-green-l3',
    elementGroups: [
      {
        name: 'Sample Group 1',
        description: `<p>Compare the areas of shapes and surfaces (NSW only)</p><p>The Australian Curriculum has no content for Area until Year 4</p><p>The Numeracy Progression V3 / General Capabilities V9 has no content for Area Until Uum3 / Understanding units of measurement P3 Using informal units of measurement in Year 1 and 2</p>`,
        year: 3,
        concept_zone: '123456',
        elements: [
          // {
          //   code: 'G4',
          //   description: '3D OBJECTS, 2D SURFACES, STRAIGHT & CURVED LINES',
          //   color: '#AA2B56',
          //   border_color: '#D596AB',
          // },
          {
            code: 'G4',
            description: 'GIVE AND FOLLOW DIRECTIONS',
            color: '#CC0000',
            border_color: '#D596AB',
          },
        ],
      },
    ],
  },
  {
    id: 'y1',
    name: 'Year 1',
    color: 'yellow',
    bg_faded_color: 'bg-yellow-l3',
    has_text: 'Scope and Sequence',
  },
  {
    id: 'y2',
    name: 'Year 2',
    color: 'red',
    bg_faded_color: 'bg-red-l3',
    has_text: 'Scope and Sequence',
  },
  {
    id: 'y3',
    name: 'Year 3',
    color: 'brown',
    bg_faded_color: 'bg-brown-l1',
    has_text: 'Scope and Sequence',
  },
  {
    id: 'y4',
    name: 'Year 4',
    color: 'blue',
    bg_faded_color: 'bg-blue-l3',
    has_text: 'Scope and Sequence',
  },
  {
    id: 'y5',
    name: 'Year 5',
    color: 'lightblue',
    bg_faded_color: 'bg-lightblue-l1',
    has_text: 'Scope and Sequence',
  },
  {
    id: 'y6',
    name: 'Year 6',
    color: 'purple',
    bg_faded_color: 'bg-purple-l3',
    has_text: 'Scope and Sequence',
  },
];

export const resourceByConcept = [
  {
    id: 'c1',
    name: 'Number + Place Value + Addition And Subtraction + Money + Algebra',
    color: 'green',
    bg_faded_color: 'bg-green-l3',
  },
  {
    id: 'c2',
    name: 'Multiplication & Division + Fractions + Place Value + Algebra',
    color: 'yellow',
    bg_faded_color: 'bg-yellow-l3',
  },
  {
    id: 'c3',
    name: 'Fractions + Multiplication & Division + Time + Place Value + Measurement + Geometry',
    color: 'red',
    bg_faded_color: 'bg-red-l3',
  },
  {
    id: 'c4',
    name: 'Measurement + Geometry + Place Value + Fractions',
    color: 'brown',
    bg_faded_color: 'bg-brown-l1',
  },
  {
    id: 'c5',
    name: 'Patterns + Multiplication & Division',
    color: 'blue',
    bg_faded_color: 'bg-blue-l3',
  },
  {
    id: 'c6',
    name: 'Time + Position + Fractions',
    color: 'lightblue',
    bg_faded_color: 'bg-lightblue-l1',
  },
  {
    id: 'c7',
    name: 'Position + Time + Place Value',
    color: 'purple',
    bg_faded_color: 'bg-purple-l3',
  },
  {
    id: 'c8',
    name: 'Statistics & Probability + Fractions + Place Value',
    color: 'green',
    bg_faded_color: 'bg-green-l3',
  },
];

export const subsites = [
  {
    name: 'Teaching Resources',
    // url: config.subsiteUrls.tr,
    url: `${config.pageBuilderUrls.main}/teaching-resources`,
    short_code: 'TR',
  },
  {
    name: 'Student Assessment & Tracking Resources',
    // url: config.subsiteUrls.ftd,
    url: `${config.pageBuilderUrls.main}/assessment-tracking`,
    short_code: '4TD',
  },
  {
    name: 'Relational Maths<br/>Big Ideas',
    url: `${config.pageBuilderUrls.main}/relational-understanding`,
    short_code: 'ALPATP',
  },
  {
    name: 'How to Use<br/>+<br/>FAQ',
    // url: config.subsiteUrls.slr,
    url: `${config.pageBuilderUrls.main}/faq`,
    short_code: 'SLR',
  },
  {
    name: 'Maths<br/>Scope &<br/>Sequences',
    // url: config.subsiteUrls.plr,
    url: `${config.pageBuilderUrls.main}/tr-s-and-s`,
    short_code: 'PLR',
  },
  {
    name: "What's New?",
    // url: config.subsiteUrls.dvr,
    url: `${config.pageBuilderUrls.main}/whats-new`,
    short_code: 'DVR',
  },
];

export const subsitesNotSubscribed = [
  {
    name: 'Teaching Resources',
    // url: config.subsiteUrls.tr,
    url: `${config.pageBuilderUrls.main}/teaching-resources`,
    short_code: 'TR',
  },
  {
    name: 'Student Assessment & Tracking Resources',
    // url: config.subsiteUrls.ftd,
    url: `${config.pageBuilderUrls.main}/assessment-tracking-tool`,
    short_code: '4TD',
  },
  {
    name: 'Relational Maths<br/>Big Ideas',
    url: `${config.pageBuilderUrls.main}/relational-understanding`,
    short_code: 'ALPATP',
  },
  {
    name: 'How to Use<br/>+<br/>FAQ',
    // url: config.subsiteUrls.slr,
    url: `${config.pageBuilderUrls.main}/faq`,
    short_code: 'SLR',
  },
  {
    name: 'Maths<br/>Scope &<br/>Sequences',
    // url: config.subsiteUrls.plr,
    url: `${config.pageBuilderUrls.main}/tr-s-and-s`,
    short_code: 'PLR',
  },
  {
    name: "What's New?",
    // url: config.subsiteUrls.dvr,
    url: `${config.pageBuilderUrls.main}/whats-new`,
    short_code: 'DVR',
    //linkToNewTab: true,
  },
];

export const footerPages = [
  {
    displayName: 'Look Around',
    urlPath: `${config.pageBuilderUrls.main}/look-around`,
    isProtected: false,
  },
  {
    displayName: 'Relational Mathematics',
    urlPath: `${config.pageBuilderUrls.main}/relational-understanding`,
    isProtected: false,
  },
  {
    displayName: 'Research-base',
    urlPath: `${config.pageBuilderUrls.main}/research-base`,
    isProtected: false,
  },
  {
    displayName: 'Free Resources',
    urlPath: `${config.pageBuilderUrls.main}/free-resources`,
    isProtected: false,
  },
];

export const sitepages = [
  // {
  //   name: 'My Account',
  //   url: '',
  // },
  // {
  //   name: 'Membership',
  //   url: '',
  // },
  {
    name: 'About / Contact',
    url: `${config.pageBuilderUrls.main}/about-contact`,
  },
  {
    name: 'Terms & Conditions',
    url: `${config.subsitesUrl.main}/agreement/termsandconditions`,
  },
  {
    name: 'Privacy Policy',
    url: `${config.subsitesUrl.main}/agreement/privacypolicy`,
  },
  {
    name: 'What’s New?',
    url: `${config.pageBuilderUrls.main}/whats-new`,
  },
];

export const footerSubsites = [
  {
    name: 'Homepage',
    url: '/',
    short_code: 'HOME',
  },
  {
    name: 'Teaching Resources',
    // url: config.subsiteUrl.tr,
    url: 'dashboard/tr',
    short_code: 'TR',
  },
  {
    name: 'Maths Scope and Sequences',
    url: `${config.pageBuilderUrls.main}/tr-s-and-s`,
    short_code: 'SAS',
  },
  {
    name: 'Maths Concept Sequences',
    url: `${config.pageBuilderUrls.main}/tr-s-and-s`,
    short_code: 'SAS',
  },
  {
    name: 'Assessment & Tracking',
    url: `${config.pageBuilderUrls.main}/assessment-tracking`,
    short_code: 'TAR',
  },
  {
    name: 'Professional Learning',
    // url: config.subsiteUrls.ftd,
    url: `${config.pageBuilderUrls.main}/professional-learning`,
    short_code: '4TD',
  },
  {
    name: 'Student Resources',
    // url: config.subsiteUrls.slr,
    url: `${config.pageBuilderUrls.main}/student-learning-resources`,
    short_code: 'SLR',
  },
  {
    name: 'Digital Resources',
    url: `${config.pageBuilderUrls.main}/digital-resources`,
    short_code: 'DVR',
  },
];

export const footerSubsitesNotSubscribed = [
  {
    name: 'Homepage',
    url: '/',
    short_code: 'HOME',
  },
  {
    name: 'Teaching Resources',
    // url: config.subsiteUrl.tr,
    url: 'dashboard/tr',
    short_code: 'TR',
  },
  {
    name: 'Maths Scope and Sequences',
    url: `${config.pageBuilderUrls.main}/dashboard/tr/scopeandsequence?selected=0`,
    short_code: 'SAS',
  },
  {
    name: 'Maths Concept Sequences',
    url: `${config.pageBuilderUrls.main}/dashboard/tr/scopeandsequence?selected=0`,
    short_code: 'SAS',
  },
  {
    name: 'Assessment & Tracking',
    url: `${config.pageBuilderUrls.main}/assessment-tracking-tool`,
    short_code: 'TAR',
  },
  {
    name: 'Professional Learning',
    // url: config.subsiteUrls.ftd,
    url: `${config.pageBuilderUrls.main}/professional-learning`,
    short_code: '4TD',
  },
  {
    name: 'Student Resources',
    // url: config.subsiteUrls.slr,
    url: `${config.pageBuilderUrls.main}/student-learning-resources`,
    short_code: 'SLR',
  },
  {
    name: 'Digital Resources',
    url: `${config.pageBuilderUrls.main}/digital-resources`,
    short_code: 'DVR',
  },
];

export const socmed = [
  {
    icon: 'alp-fb',
    url: 'https://www.facebook.com/alearningplaceateachingplace',
  },
  // {
  //   icon: 'alp-linkedin',
  //   url: '#',
  // },
  {
    icon: 'alp-instagram',
    url: 'https://www.instagram.com/alearningplaceateachingplace/',
  },
  // {
  //   icon: 'alp-youtube',
  //   url: '#',
  // },
];

export const boxColor = new Map();
boxColor.set(0, 'green');
boxColor.set(1, 'yellow');
boxColor.set(2, 'red');
boxColor.set(3, 'brown');
boxColor.set(4, 'blue');
boxColor.set(5, 'lightblue');
boxColor.set(6, 'purple');

export const scopeSequenceColor = new Map();
scopeSequenceColor.set(0, 'green');
scopeSequenceColor.set(1, 'yellow');
scopeSequenceColor.set(2, 'red');
scopeSequenceColor.set(3, 'purple');
scopeSequenceColor.set(4, 'blue');

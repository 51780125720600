import { useEffect, useRef, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { useNode, useEditor } from '@craftjs/core';
import { ROOT_NODE } from '@craftjs/utils';
import styled from 'styled-components';

import type { MouseEvent } from 'react';

import { ReactComponent as ArrowUp } from '../../../assets/pagebuilder-icons/arrow-up.svg';
import { ReactComponent as Delete } from '../../../assets/pagebuilder-icons/delete.svg';
import { ReactComponent as Move } from '../../../assets/pagebuilder-icons/move.svg';

const IndicatorDiv = styled.div`
  height: 30px;
  margin-top: -29px;
  font-size: 12px;
  line-height: 12px;

  svg {
    fill: #fff;
    width: 15px;
    height: 15px;
  }
`;

const Btn = styled.a`
  padding: 0 0px;
  opacity: 0.9;
  display: flex;
  align-items: center;
  > div {
    position: relative;
    top: -50%;
    left: -50%;
  }
`;

function RenderNode({ render }: any) {
  const { id } = useNode();
  const { actions, query, isActive } = useEditor((state) => ({
    isActive: state.nodes[id].events.selected,
  }));

  const {
    isHover,
    dom,
    name,
    moveable,
    deletable,
    connectors: { drag },
    parent,
  } = useNode((node) => ({
    isHover: node.events.hovered,
    dom: node.dom,
    name: node.data.custom.displayName || node.data.displayName,
    moveable: query.node(node.id).isDraggable(),
    deletable: query.node(node.id).isDeletable(),
    parent: node.data.parent,
    props: node.data.props,
  }));

  const currentRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (dom) {
      if (isActive || isHover) {
        dom.classList.add('component-selected');
      } else {
        dom.classList.remove('component-selected');
      }
    }
  }, [dom, isActive, isHover]);

  const getPos = useCallback((dom: HTMLElement) => {
    const { top, left, bottom } = dom ? dom.getBoundingClientRect() : { top: 0, left: 0, bottom: 0 };

    return {
      top: `${top > 0 ? top : bottom}px`,
      left: `${left}px`,
    };
  }, []);

  const scroll = useCallback(() => {
    const { current: currentDOM } = currentRef;

    if (!currentDOM) return;

    const { top, left } = getPos(dom as HTMLElement);

    currentDOM.style.top = top;
    currentDOM.style.left = left;
  }, [dom, getPos]);

  useEffect(() => {
    document.addEventListener('scroll', scroll);

    return () => {
      document.removeEventListener('scoll', scroll);
    };
  }, [scroll]);

  return (
    <>
      {isHover || isActive
        ? ReactDOM.createPortal(
            <IndicatorDiv
              ref={currentRef as React.MutableRefObject<HTMLDivElement>}
              className="tw-fixed tw-flex tw-items-center tw-bg-primary tw-px-2 tw-py-2 tw-text-white"
              style={{
                left: getPos(dom as HTMLElement).left,
                top: getPos(dom as HTMLElement).top,
              }}
            >
              <div className="tw-mr-4 tw-flex-1">{name}</div>

              {moveable ? (
                <Btn className="tw-mr-2 tw-cursor-move" ref={drag as any}>
                  <Move />
                </Btn>
              ) : null}

              {id !== ROOT_NODE && (
                <Btn
                  className="tw-mr-2 tw-cursor-pointer"
                  onClick={() => {
                    actions.selectNode(parent as string);
                  }}
                >
                  <ArrowUp />
                </Btn>
              )}

              {deletable ? (
                <Btn
                  className="tw-cursor-pointer"
                  onMouseDown={(e: MouseEvent) => {
                    e.stopPropagation();
                    actions.delete(id);
                  }}
                >
                  <Delete />
                </Btn>
              ) : null}
            </IndicatorDiv>,
            document.querySelector('.page-container') as Element
          )
        : null}
      {render}
    </>
  );
}

export default RenderNode;

import { useRef } from 'react';
import './ViewFilePage.scss';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import useRouterDom from '../../hooks/useRouterDom';
import { Button } from 'reactstrap';

function ViewFilePage() {
  const FileviewerRef = useRef(null);
  const { searchParams } = useRouterDom();

  const handleFileDownload = async (url: string, filename: string) => {
    const response = await fetch(url);
    const blob = await response.blob();

    const anchorElement = document.createElement('a');
    anchorElement.href = URL.createObjectURL(blob);
    anchorElement.setAttribute('download', filename);
    anchorElement.style.display = 'none';
    document.body.appendChild(anchorElement);
    anchorElement.click();
    document.body.removeChild(anchorElement);

    URL.revokeObjectURL(anchorElement.href);
  };

  return (
    <div className="file-wrapper flex flex-col flex-h-center" ref={FileviewerRef}>
      <header className="flex flex-row relative">
        <div className="displayname-col absolute">
          <h6>{decodeURIComponent(searchParams.get('name') ?? '')}</h6>
        </div>
        <div
          className={`${
            !searchParams.get('pair') || searchParams.get('pair') === 'none' ? 'page-nav' : 'page-nav2'
          } m-auto flex flex-row flex-h-center`}
        >
        </div>
        <Button
          onClick={() => handleFileDownload(searchParams.get('url') ?? '', searchParams.get('name') ?? '')}
          className="reversed-color-btn"
        >
          Click here to Download
        </Button>
      </header>
      <h3 className="label-download-placeholder">You can view the file by clicking on the download button.</h3>
    </div>
  );
}

export default ViewFilePage;

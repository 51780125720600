import React, { useEffect, useState } from 'react';
import './Navigation.scss';
import { NavLink } from 'react-router-dom';
//import subsites from '../../../config/subsites';
import config from '../../../config';
import { Button } from 'reactstrap';
import { AlpLogo } from '../../../helpers/imagePreloader';
import {
  userContextApi,
  useGetUserContext,
  useLazyGetUserContextQuery,
  useLazyGetUserLatestSubscriptionQuery,
} from '../../../store/services/userContextApi';
// import HexIcon from '../../../components/CustomIcons/HexIcon/HexIcon';
import cookie from '../../../helpers/cookieHelper';
//import { subsiteThemes } from '../../../helpers/constants';
import UserCard from '../UserCard/UserCard';
import { getTopmenuItems } from '../Utils/utilityFunctions';
import useLayout from '../hooks/useLayout';
import Image from '../../../components/Image/Image';

function Navigation(props: any) {
  const { isSideNavigationVisible, sideMenuId } = useLayout();
  const link = document.getElementById('homeID');

  useEffect(() => {
    if ((link as any)?.href === window.location.href) {
      (link as any).classList.add('isActiveBtn');
    } else {
      (link as any)?.classList?.remove('isActiveBtn');
    }
  }, [link, window.location.href]);
  const { data: userData } = useGetUserContext('');
  // @ts-ignore
  const { data: subscriptionData } = userContextApi.endpoints.getUserLatestSubscription.useQueryState('', {
    selectFromResult: (result: any) => result,
  });
  const [getUserLatestSubscriptionQuery] = useLazyGetUserLatestSubscriptionQuery();
  const [getUserContext] = useLazyGetUserContextQuery();
  const [name, setName] = useState('');
  const [userId, setUserId] = useState('');
  // const [role, setRole] = useState('AH');
  const [menuItems, setMenuItems] = useState<any[]>(getTopmenuItems(props.is_subscribed));
  const [dropdownOpened, setDropdownOpened] = useState('');

  // @TODO: This is temporary only. revert these changes once everything is stable
  const menus = (): any[] => {
    return (
      menuItems
        ? menuItems.map((item) => {
            if (userData) {
              if (
                userData.roles.length > 0 &&
                userData.roles.find((r: any) => r.role.short_code == 'FREESUB') &&
                item.displayName == 'My Account'
              )
                return null;
            }
            return item;
          })
        : []
    ).filter((m) => m);
  };

  useEffect(() => {
    if (cookie.isLoggedIn()) getUserContext('');
  }, []);

  useEffect(() => {
    const user = userData as any;
    if (user) {
      let name = '';
      if (user.is_institution) {
        name = user.institution_profile ? user.institution_profile?.institution_name : '';
      } else {
        name = user.user_profile ? `${user.user_profile?.first_name} ${user.user_profile?.last_name}` : '';
      }

      name.trim() !== '' ? setName(name) : setName(user.email);
      setUserId(user?.id);
      //get user subscription
      getUserLatestSubscriptionQuery('');

      //set Role
      // if (user?.roles?.find((x: any) => x.role?.short_code === 'MA')) setRole('MA');
      // else if (user?.roles?.find((x: any) => x.role?.short_code === 'SUB')) setRole('SUB');
      // else if (user?.roles?.find((x: any) => x.role?.short_code === 'SV')) setRole('SV');
      // else if (user?.roles?.find((x: any) => x.role?.short_code === 'LNS')) setRole('LNS');
      // else if (user?.roles?.find((x: any) => x.role?.short_code === 'SL')) setRole('SL');
    }
  }, [userData]);

  useEffect(() => {
    setMenuItems(getTopmenuItems(cookie.isLoggedIn() && userData && userData.licenses.length > 0));
  }, [cookie.isLoggedIn(), userData]);

  // useEffect(() => {
  //   setMenuItems(getTopmenuItems(props.is_subscribed));
  // }, [props.is_subscribed]);

  const getCarretIcon = (dropdownId: string) => {
    return dropdownOpened === dropdownId ? (
      <i className="icon icon-chevron-up nav-dropdown-icon" />
    ) : (
      <i className="icon icon-chevron-down nav-dropdown-icon" />
    );
  };

  const getMenu = (startSlice: number, endSlice?: number): JSX.Element[] => {
    return menus()
      .slice(startSlice, endSlice)
      .map((item: any, key: number) => {
        if ((!item.isProtected && !cookie.isLoggedIn()) || (!item.hideAfterLogin && cookie.isLoggedIn())) {
          return (
            <li
              key={key}
              className={item.displayName.length > 40 ? 'w-shrink' : item.displayName.length > 22 ? 'w-shrink-2' : 'no-shrink'}
            >
              {!item.dropdownId ? (
                <NavLink
                  to={item.urlPath}
                  target={item.linkToNewTab ? '_blank' : ''}
                  onClick={() => setDropdownOpened('')}
                  className={({ isActive }) =>
                    isActive || (isSideNavigationVisible && item.hasSidebar && sideMenuId === item.sideMenuId)
                      ? 'menu-selected'
                      : ''
                  }
                  // className="menu-selected"
                  end
                >
                  <span>{item.displayName}</span>

                  <div className="selected-underline" />
                </NavLink>
              ) : (
                // if the menu is a dropdown, we use div instead of navlink to prevent redirect and just show the submenu
                <div
                  className="main-menu-dropdownmenu"
                  onClick={() => setDropdownOpened((state) => (state === item.dropdownId ? '' : item.dropdownId))}
                >
                  <span>{item.displayName}</span>

                  {getCarretIcon(item.dropdownId)}
                </div>
              )}

              <div className="selected-underline" />
            </li>
          );
        } else {
          return <React.Fragment key={key} />;
        }
      });
  };

  /* const getSubsitesMenu = (): JSX.Element[] => {
    return subsites.map((item: any, key: number) => {
      const { color } = subsiteThemes.get(item.short_code);
      if (item.short_code === 'TR' && cookie.isLoggedIn()) {
        return (
          <li key={key}>
            <NavLink
              to="dashboard/tr"
              className={({ isActive }) =>
                isActive || (isSideNavigationVisible && sideMenuId === 'dashboard') ? `${color} menu-selected` : `${color}`
              }
              end
            >
              {item.name}
            </NavLink>
          </li>
        );
      } else {
        return (
          <li key={key}>
            <a href={item.url} target="_blank" rel="noreferrer" className={color}>
              {item.name}
            </a>
          </li>
        );
      }
    });
  }; */

  return (
    <>
      <header className="main-header flex flex-col">
        <div className={`navigation-row`}>
          <nav className="flex flex-row">
            <div className="brand-col-wrapper flex flex-row">
              {/* Brand column */}
              <div className="brand-col">
                {/* <a href={`${config.subsitesUrl.main}`}> */}
                <a href="#">
                  <Image src={AlpLogo} alt="A Learning Place Logo" />
                </a>
              </div>

              {/* Menu column */}
              {/* <ul className="menu-col flex flex-row"><li><a href='/' id='homeID' rel='preload'>Home <div className="selected-underline" /></a></li>{getMenu()}</ul> */}
              <div className="flex flex-col flex_1">
                <div className="flex flex-row flex_1">
                  <ul className="menu-col flex flex-row flex_1">{getMenu(0, 6)}</ul>
                  {/* Buttons column */}
                  <div className="buttons-col flex flex-row">
                    {/* <NavLink to="/activate">
                        <Button color="link" className="horizontally-center activate-btn">
                          <HexIcon icon="key" colorTheme="green" />
                          Activate a Key
                        </Button>
                      </NavLink>

                      <div className="divider" /> */}

                    {cookie.isLoggedIn() ? (
                      <UserCard name={name} isInstitution={userData?.is_institution} userId={userId} />
                    ) : (
                      <>
                        <a href={`${config.subsitesUrl.main}/login/redirect/${config.subsiteCode}`}>
                          <Button color="link" className="login-btn">
                            Login
                          </Button>
                        </a>

                        <a href={`${config.subsitesUrl.main}/createaccount`}>
                          <Button className="reversed-color-btn">Create A Free Account</Button>
                        </a>
                      </>
                    )}
                  </div>
                </div>

                <ul className="menu-col flex flex-row flex_1">{getMenu(6)}</ul>
              </div>
            </div>
          </nav>
        </div>
        <div className={`subsite-row flex with-shadow`}>{/* <ul className="flex flex-row">{getSubsitesMenu()}</ul> */}</div>
      </header>
    </>
  );
}

export default Navigation;
